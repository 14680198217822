.react-bootstrap-table table {
  table-layout: auto;
}

.search-label {
  width: 100%;
}

.page-item.active .page-link {
  z-index: 0!important;
}
