.payment-link {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: 1px solid lightgray;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  transition: box-shadow 0.2s ease-in;
  height: 80px;
  padding: 5px;
  width: 80%;
  img {
    max-width: 100%;
  }
}

.payment-link.active {
  background: #c4c9d0;
}

.payment-link:hover, .payment-link.active:hover {
  background: #d8dbe0;
}
